/* eslint-disable max-len */

export const colors = {
  orange: "#ff6c2f",
  darkerOrange: "#FB6C2E",
  grey: "#EFEFEF",
};

export const breakpoints = {
  desktop: 1280,
  desktopPlus: 1366,
};

export const heights = {
  headerMobile: 63,
};

export const spacers = {
  wrapper: {
    mobile: 20,
    desktop: 30,
    desktopPlus: 38,
  },
  grid: {
    mobile: 15,
    desktop: 30,
  },
};

export const imgix = {
  header: {
    fit: "crop",
    crop: "entropy",
    q: 20,
    w:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 768
          : window.innerWidth < 1280
          ? 1280
          : 1280
        : 1280,
    h:
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 554
          : window.innerWidth < 1280
          ? 922
          : 922
        : 922,
  },
  smaller: {
    fit: "crop",
    crop: "entropy",
    q: 20,
    w: 1440,
    h: 1000,
  },
  small: {
    fit: "crop",
    crop: "entropy",
    q: 20,
    w: 660,
    h: 660,
  },
  mitarbeiter: {
    fit: "crop",
    crop: "faces",
    q: 20,
    w: 660,
    h: 660,
  },
};
